export const OPEN_MODAL: string = "OPEN_MODAL";
export const CLOSE_MODAL: string = "CLOSE_MODAL";
export const GET_ERRORS: string = "GET_ERRORS";
export const CLEAR_ERRORS: string = "CLEAR_ERRORS";
export const SET_CURRENT_USER: string = "SET_CURRENT_USER";
export const GET_GAUGES: string = "GET_GAUGES";
export const GET_LOGS: string = "GET_LOGS";
export const APPEND_LOGS: string = "APPEND_LOGS";
export const GENERATE_FILTERED_LIST: string = "GENERATE_FILTERED_LIST";
export const OPEN_INFO: string = "OPEN_INFO";
export const CLOSE_INFO: string = "CLOSE_INFO";
export const ADD_HISTORIC_FLOW: string = "ADD_HISTORIC_FLOW";
export const GET_GAUGE_HISTORY: string = "GET_GAUGE_HISTORY";
export const EDIT_LOG: string = "EDIT_LOG";
export const UPDATE_OPEN_LOG: string = "UPDATE_PRE_EDIT_STATE";
export const DELETE_LOG: string = "DELETE_LOG";
export const SET_CATEGORY: string = "SET_CATEGORY";
export const LOADING_GUIDES: string = "LOADING_GUIDES";
export const ADD_TO_FAVOURITES: string = "ADD_TO_FAVOURITES";
export const REMOVE_FROM_FAVOURITES: string = "REMOVE_FROM_FAVOURITES";
export const SET_MAP_BOUNDS: string = "SET_MAP_BOUNDS";
export const GET_SENSOR_DATA: string = "GET_SENSOR_DATA";
export const GET_ENTRIES: string = "GET_ENTRIES";
export const LOADING_ENTRIES: string = "LOADING_ENTRIES";
export const GET_ITEM_DETAILS: string = "GET_ITEM_DETAILS";
export const GET_WEATHER: string = "GET_WEATHER";
export const SET_FILTER: string = "SET_FILTER";
export const CLEAR_GAUGE_HISTORY: string = "CLEAR_GAUGE_HISTORY";
export const GENERATE_FILTERED_LOG_LIST: string = "GENERATE_FILTERED_LOG_LIST";
export const SET_LOG_GUIDE_NAMES: string = "SET_LOG_GUIDE_NAMES";
export const SET_SELECTED_LOG_ID: string = "SET_SELECTED_LOG_ID";
export const LOADING_LOG_ENTRIES: string = "LOADING_LOG_ENTRIES";
export const OPEN_LOG_PAGE: string = "OPEN_LOG_PAGE";
export const CLOSE_LOG_PAGE: string = "CLOSE_LOG_PAGE";
export const SET_TAB_INDEX: string = "SET_TAB_INDEX";
export const SET_LOADING_SPINNER: string = "SET_LOADING_SPINNER";
export const CLEAR_LOADING_SPINNER: string = "CLEAR_LOADING_SPINNER";
export const GET_ITEM_LOGS: string = "GET_ITEM_LOGS";
export const SET_USER_DETAILS: string = "SET_USER_DETAILS";
export const CLEAR_USER_DETAILS: string = "CLEAR_USER_DETAILS";
export const CLEAR_LOGS: string = "CLEAR_LOGS";
export const SET_SEARCH_PANEL: string = "SET_SEARCH_PANEL";
export const SET_SEARCH_STRING_FILTER: string = "SET_SEARCH_STRING_FILTER";
export const SET_ACTIVITY_FILTER: string = "SET_ACTIVITY_FILTER";
export const GET_GAUGE_DISCLAIMER: string = "GET_GAUGE_DISCLAIMER";
export const CLEAR_GAUGE_DISCLAIMER: string = "CLEAR_GAUGE_DISCLAIMER";
export const SET_EXPANSION_PANELS: string = "SET_EXPANSION_PANELS";
export const ADD_TO_RECENTS: string = "ADD_TO_RECENTS";
export const GET_GUIDES: string = "GET_GUIDES";
export const SET_VIEWPORT: string = "SET_VIEWPORT";
export const APPEND_ENTRIES: string = "APPEND_ENTRIES";
export const GENERATE_FILTERED_LIST_APPEND: string = "GENERATE_FILTERED_LIST_APPEND";
export const RESET_PASSWORD_SUCCESS: string = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL: string = "RESET_PASSWORD_FAIL";
export const RESET_PASSWORD_SENT: string = "RESET_PASSWORD_SENT";
export const RESET_PASSWORD_SENT_RESET: string = "RESET_PASSWORD_SENT_RESET";
export const GET_GUIDE_DRAFTS: string = "GET_GUIDE_DRAFTS";
export const CLEAR_GUIDE_DRAFTS: string = "CLEAR_GUIDE_DRAFTS";
export const CLEAR_NOTICES: string = "CLEAR_NOTICES";
export const GET_NOTICES: string = "GET_NOTICES";
export const SET_BANNER_PAGE: string = "SET_BANNER_PAGE";
